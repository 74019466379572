<template>
  <div>
    <section class="faq-contact">
      <b-row class="pricing-card">
        <b-col
          offset-sm-2
          sm="10"
          md="12"
          offset-lg="2"
          lg="10"
          class="mx-auto"
        >
          <b-row>
            <b-col md="4" v-for="paket in dataPaket" v-bind:key="paket.id">
              <b-card align="center">
                <!-- img -->
                <b-img
                  class="mb-2 mt-5"
                  alt="basic svg img"
                  :src="getUrl + 'penyimpanan/paket/' + paket.photo"
                  style="width: 50px"
                />
                <!--/ img -->
                <h3>{{ paket.title }}</h3>
                <b-card-text> {{ paket.description }} </b-card-text>

                <!-- annual plan -->
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <small class="font-medium-1 font-weight-bold text-primary"
                      >Rp.
                    </small>
                    <span
                      class="
                        pricing-basic-value
                        font-weight-bolder
                        text-primary
                      "
                      >{{ formatPrice(paket.price) }}</span
                    >
                    <small
                      class="
                        pricing-duration
                        text-body
                        font-medium-1 font-weight-bold
                      "
                    >
                      /bulan</small
                    >
                  </div>
                </div>
                <!--/ annual plan -->

                <!-- plan benefit -->
                <b-list-group class="list-group-circle text-left">
                  <b-list-group-item> {{ paket.list_1 }} </b-list-group-item>
                  <b-list-group-item> {{ paket.list_2 }} </b-list-group-item>
                  <b-list-group-item> {{ paket.list_3 }} </b-list-group-item>
                  <b-list-group-item> {{ paket.list_4 }} </b-list-group-item>
                  <b-list-group-item> {{ paket.list_5 }} </b-list-group-item>
                  <b-list-group-item> {{ paket.list_6 }} </b-list-group-item>
                  <b-list-group-item> {{ paket.list_7 }} </b-list-group-item>
                  <b-list-group-item> {{ paket.list_8 }} </b-list-group-item>
                  <b-list-group-item> {{ paket.list_9 }} </b-list-group-item>
                  <b-list-group-item> {{ paket.list_10 }} </b-list-group-item>
                </b-list-group>
                <!--/ plan benefit -->

                <!-- buttons -->
                  <b-button
                    block
                    class="mt-2"
                    variant="primary"
                    @click="
                      $router.push({
                        name: 'add_upgrade_akun',
                        params: { id: paket.id },
                      })
                    "
                  >
                    Beli Paket
                  </b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
} from "bootstrap-vue";
import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    ContentWithSidebar,
  },
  data() {
    return {
      search_query: "",
      currentPage: 1,
      perPage: 1,
      rows: 50,
      dataPaket: [],
      level: localStorage.getItem("Ulevel"),
      selected: "left",
      selected2: "right",
      getUrl: "",
    };
  },
  async mounted() {
    await this.getData();
    this.getUrl = process.env.VUE_APP_URL;
    // console.log("cek", process.env.VUE_APP_URL)
  },
  methods: {
    async getData() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "pakets/view")
        .then((res) => {
          this.dataPaket = res.data.data;
        });
    },
    formatPrice(value) {
      if (value != null) {
        const val = Number(value).toFixed(2).replace(/\.00$/, "");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-blog.scss";
</style>
